/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { first } from "lodash";
import { DateTime } from "luxon";
import { Box, Stack } from "@mui/material";
import BaseSelect from "../../../Common/BaseSelect/BaseSelect";
import { TAppState } from "../../../../store/reducers";
import { AppDispatch } from "../../../../store/configureStore";
import { signalSelector } from "../../../selectors";
import {
  useGetActivePeriodsQuery,
  useGetActivePairsQuery,
} from "../../../../features/api";
import BaseDatePicker from "../../../Common/BaseDatePicker/BaseDatePicker";
import { disableDates, getMaxDate } from "../../../../utilities/helpers";
import {
  analysisDatasetPairsChanged,
  analysisDatasetPeriodsChanged,
  analysisOpenedAtChanged,
  analysisPairChanged,
  analysisPeriodChanged,
} from "../../../../store/reducers/signal";
import { fetchLastSynced } from "../../../../store/actions/period";
import { fetchCandles } from "../../../../store/actions/charts";

const SignalsAnalysisFilters: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { data: periodData, isLoading: periodsLoading } =
    useGetActivePeriodsQuery();
  const { data: pairData, isLoading: pairsLoading } = useGetActivePairsQuery();
  const {
    analysis: {
      dataset: { periods, pairs },
      filters: { period, pair, openedAt },
    },
  } = useSelector((state: TAppState) => signalSelector(state));

  const periodSelected = (period: any) => {
    dispatch(analysisPeriodChanged(period));
  };

  const pairSelected = (pair: any) => {
    dispatch(analysisPairChanged(pair));
  };

  const dateSelected = (openedAt: any) => {
    dispatch(analysisOpenedAtChanged(openedAt));
  };

  useEffect(() => {
    if (periodData && !periodsLoading) {
      dispatch(analysisDatasetPeriodsChanged(periodData));
      dispatch(analysisPeriodChanged(first(periodData)));
    }
  }, [dispatch, periodData, periodsLoading]);

  useEffect(() => {
    if (pairData && !pairsLoading) {
      dispatch(analysisDatasetPairsChanged(pairData));
      dispatch(analysisPairChanged(first(pairData)));
    }
  }, [dispatch, pairData, pairsLoading]);

  useEffect(() => {
    const periodId = period?.ID;
    if (periodId) {
      const setOpenedAt = async () => {
        const {
          payload: { openedAt },
        } = await dispatch(fetchLastSynced({ periodId }));
        dispatch(
          analysisOpenedAtChanged(
            DateTime.fromFormat(openedAt, "yyyy-MM-dd HH:mm:ss", {
              zone: "utc",
            })
          )
        );
      };
      setOpenedAt();
    }
  }, [dispatch, period]);

  useEffect(() => {
    if (period && pair && openedAt) {
      const pairId = pair?.ID;
      const date = openedAt.toFormat("yyyy-MM-dd HH:mm:ss", { zone: "utc" });
      dispatch(
        fetchCandles({
          period,
          pair: { ID: pairId },
          openedAt: date,
        })
      );
    }
  }, [dispatch, pair, openedAt]);

  return (
    <Stack
      spacing={{ xs: 2, sm: 2 }}
      direction="row"
      useFlexGap
      flexWrap="wrap"
      marginTop={2}
      marginBottom={2}
    >
      <Box>
        <BaseSelect
          data={periods}
          loading={periodsLoading}
          onChange={periodSelected}
          selectedDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          optionsDisplay={(row: any) =>
            `${t(`PERIOD_SELECT_DISPLAY_LABEL_${row.CODE.toUpperCase()}`)} (${
              row.SYMBOL
            })`
          }
          selected={[period?.CODE || ""]}
          selectKey="CODE"
          inputLabel={t("PERIOD_SELECT_INPUT_LABEL")}
          label={t("PERIOD_SELECT_LABEL")}
          minWidth={150}
          maxWidth={150}
        />
      </Box>
      <Box>
        <BaseSelect
          data={pairs}
          loading={pairsLoading}
          onChange={pairSelected}
          selected={[pair?.ID || ""]}
          selectKey="ID"
          displayKey="SYMBOL"
          inputLabel={t("PAIR_SELECT_INPUT_LABEL")}
          label={t("PAIR_SELECT_LABEL")}
          minWidth={200}
          maxWidth={200}
          selectedDisplay={(row, key) =>
            key ? row[key].replaceAll(row.SYMBOL_PREFIX, "") : ""
          }
          optionsDisplay={(row, key) =>
            key ? row[key].replaceAll(row.SYMBOL_PREFIX, "") : ""
          }
          optionInfo={["TYPE_NAME", "PROVIDER_NAME"]}
          optionInfoStyle={{
            fontSize: "70%",
            color: "rgba(212,255,0,0.8)",
            paddingBottom: "1%",
          }}
          sortFn={(row: any) => [row.SYMBOL.replaceAll(row.SYMBOL_PREFIX, "")]}
          hasSearch
          searchKey="SYMBOL"
        />
      </Box>
      <Box>
        <BaseDatePicker
          label={t("OPENED_AT_DATE_PICKER_LABEL")}
          defaultValue={openedAt}
          maxDate={getMaxDate(period)}
          onChange={dateSelected}
          timeSteps={{ hours: 24, minutes: 60 }}
          shouldDisableDate={disableDates(period)}
        />
      </Box>
    </Stack>
  );
};

export default SignalsAnalysisFilters;
